<template>
  <div class="w-full">
    <header
      class="flex flex-wrap md:flex-nowrap justify-between gap-y-3 gap-x-8 mb-8"
    >
      <input
        v-model="transactionKeywordModel"
        class="w-full h-10 border border-greyscale-7 text-sm text-primary rounded-[5px] focus:outline-primary placeholder:text-sm px-4 py-1 placeholder:text-primary-primary bg-gray-50"
        placeholder="Search transactions by description or id"
        type="text"
      />
    </header>
    <div
      class="w-full flex flex-wrap xl:flex-nowrap gap-y-5 justify-between items-center gap-x-4 mb-4"
    >
      <div
        class="w-full grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-x-5 gap-y-4"
      >
        <app-input
          label="Type"
          type="select"
          align-row
          grey-text
          v-bind="filterFields.types"
        >
          <option value="" selected>All types</option>
          <option value="DEPOSIT">DEPOSIT</option>
          <option value="TRANSFER">TRANSFER</option>
          <option value="EXCHANGE">EXCHANGE</option>
          <option value="OTC">OTC</option>
        </app-input>
        <app-input
          label="Date from"
          placeholder="Select date"
          type="date"
          v-bind="filterFields.created_date_from"
          align-row
          grey-text
        />
        <app-input
          label="Date to"
          placeholder="Select date"
          v-bind="filterFields.created_date_to"
          type="date"
          align-row
          grey-text
        />
        <app-input
          label="Status"
          type="select"
          align-row
          grey-text
          v-bind="filterFields.states"
        >
          <option value="" selected>All statuses</option>
          <option value="PENDING">PENDING</option>
          <option value="PROCESSING">PROCESSING</option>
          <option value="COMPLETED">COMPLETED</option>
          <option value="FAILED">FAILED</option>
          <option value="CANCELLED">CANCELLED</option>
        </app-input>
      </div>
      <button
        class="bg-[#f6f6f6] text-primary px-4 py-1 h-[35px] flex gap-x-2 items-center text-sm rounded-[5px]"
        type="button"
        @click="resetFilters"
      >
        <reset-icon />
        Reset
      </button>
    </div>
    <div class="w-full mt-8">
      <app-table
        v-if="!isError"
        :data="transactions?.data.items"
        :columns="columns"
        :loading="isLoading"
        :pagination="{
          currentPage,
          perPage,
          totalItems: transactions?.data.total_items || 0,
        }"
        @mobile-row-click="handleMobileRowClicked"
        @change="handleTableChange"
      >
        <template #column-account="props">
          <div>
            <div class="flex gap-x-1">
              <asset-type :asset="props.row.currency" />
            </div>
          </div>
        </template>

        <template #column-status="props">
          <transaction-status :status="props.row.state" />
        </template>

        <template #column-amount="props">
          <div v-if="props.row.type === 'DEPOSIT'" class="font-bold">
            {{
              `${props.row.currency} ${formatAmountToMajor(
                props.row.destination_amount,
                props.row.currency,
              )} `
            }}
          </div>
          <div v-else class="font-bold">
            {{
              `${props.row.currency} ${formatAmountToMajor(
                props.row.sending_amount,
                props.row.currency,
              )} `
            }}
          </div>
        </template>

        <template #column-type="props">
          <div
            v-if="isTransactionOtc(props.row)"
            class="flex gap-x-1 items-center"
          >
            <debit-icon />
            <span class="capitalize leading-[0px] text-[#B96B6B]">Otc</span>
          </div>
          <div v-else class="flex gap-x-1 items-center">
            <credit-icon v-if="props.row.type === 'DEPOSIT'" />
            <debit-icon v-else-if="props.row.type === 'TRANSFER'" />
            <exchange-icon v-else-if="props.row.type === 'EXCHANGE'" />
            <span
              class="capitalize leading-[0px]"
              :class="
                props.row.type === 'DEPOSIT'
                  ? 'text-[#297FB0]'
                  : props.row.type === 'TRANSFER'
                    ? 'text-[#B96B6B]'
                    : 'text-processing/70'
              "
              >{{ props.row.type?.toLowerCase() }}</span
            >
          </div>
        </template>

        <template #column-beneficiary="props">
          <div
            v-if="props.row.type === 'DEPOSIT' && props.row.source.counterparty"
          >
            <div class="flex gap-x-2 items-start">
              <institution-icon />

              <div class="-mt-1">
                <div>
                  {{
                    props.row.source.counterparty.details?.accountName ||
                    props.row.source.counterparty.details?.account_name ||
                    "N/A"
                  }}
                </div>
                <div class="text-xs text-text-primary">
                  {{
                    props.row.source.counterparty.details?.accountNumber ||
                    props.row.source.counterparty.details?.account_number
                  }}
                  -
                  {{
                    props.row.source.counterparty.details?.bankName ||
                    props.row.source.counterparty.details?.bank_name ||
                    "N/A"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              props.row.type !== 'DEPOSIT' && props.row.destination.counterparty
            "
          >
            <div class="flex gap-x-2 items-start">
              <institution-icon />

              <div class="-mt-1">
                <div v-if="props.row.destination.name">
                  {{ props.row.destination.name }}
                </div>
                <div class="text-xs text-text-primary">
                  {{
                    props.row.destination.counterparty.details?.accountNumber ||
                    props.row.destination.counterparty.details?.account_number
                  }}
                  -
                  {{
                    props.row.destination.counterparty.details?.bankName ||
                    props.row.destination.counterparty.details?.bank_name ||
                    "N/A"
                  }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <template #column-action="props">
          <router-link
            class="underline underline-offset-2"
            :to="`/transactions/fiat/${props.row.id}`"
          >
            View
          </router-link>
        </template>

        <!-- mobile columns -->

        <template #column-mobile-account="props">
          <div class="flex justify-between items-start gap-x-3">
            <div class="">
              <credit-icon v-if="props.row.type === 'DEPOSIT'" />
              <debit-icon v-else-if="props.row.type === 'TRANSFER'" />
              <exchange-icon v-else-if="props.row.type === 'EXCHANGE'" />
            </div>
            <div v-if="props.row.type === 'DEPOSIT'">
              <div class="flex gap-x-1">
                <asset-type :asset="props.row.currency" />
              </div>
            </div>
            <div v-else>
              <div class="flex gap-x-1">
                <asset-type :asset="props.row.currency" />
              </div>
            </div>
          </div>
        </template>

        <template #column-mobile-amount="props">
          <div v-if="props.row.type === 'DEPOSIT'">
            <div class="font-bold">
              {{
                `${props.row.currency} ${formatAmountToMajor(
                  props.row.destination_amount,
                  props.row.currency,
                )}`
              }}
            </div>
            <div class="mt-1 text-xs text-right">
              {{ formatDate(props.row.created_date) }}
            </div>
          </div>
          <div v-else>
            <div class="font-bold">
              {{
                `${props.row.currency} ${formatAmountToMajor(
                  props.row.sending_amount,
                  props.row.currency,
                )}`
              }}
            </div>
            <div class="mt-1 text-xs text-right">
              {{ formatDate(props.row.created_date) }}
            </div>
          </div>
        </template>

        <template #empty-state>
          <div
            class="w-full max-w-[300px] mx-auto flex flex-col gap-y-3 justify-center items-center"
          >
            <empty-data />
            <h4 class="font-bold text-base lg:text-lg">No transactions</h4>
            <p class="text-base text-center text-text-primary">
              Make your first payment to a beneficiary
            </p>
          </div>
        </template>
      </app-table>
      <error-component
        v-else-if="isError"
        message="Error fetching transactions"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  TableChangeParams,
  TableColumn,
} from "@/components/shared/table/table.props";
import { ServiceType, BankingTransactionResponse } from "@/types";
import { ref, watch, reactive } from "vue";
import { useGetTransactions } from "@/data-access/transactions";
import { formatAmountToMajor, formatDate } from "@/helpers";
import { useForm } from "vee-validate";
import { useRouter } from "vue-router";
import { useAppToast } from "@/composables";
import { debounce } from "lodash";

const currentPage = ref(1);
const perPage = ref(10);
const router = useRouter();
const transactionKeywordModel = ref("");
const transactionKeyword = ref("");

interface FilterFields {
  states?: string;
  asset_ids?: string;
  types?: string;
  created_date_from?: string;
  created_date_to?: string;
}

const statesFilter = ref<string[]>([]);
const typesFilter = ref<string[]>([]);
const dateFromFilter = ref<string>("");
const dateToFilter = ref<string>("");

const { defineInputBinds, values, setValues } = useForm<FilterFields>();

const isTransactionOtc = (txn: BankingTransactionResponse): boolean => {
  return txn.tags ? txn.tags.includes("OTC") : false;
};

const {
  isLoading,
  data: transactions,
  isError,
} = useGetTransactions(
  {
    page: currentPage,
    limit: perPage,
    filters: {
      keyword: transactionKeyword,
      states: statesFilter,
      type: typesFilter,
      created_date_from: dateFromFilter,
      created_date_to: dateToFilter,
    },
  },
  ServiceType.BANKING,
);

const toast = useAppToast();

watch(
  transactionKeywordModel,
  debounce((val) => {
    transactionKeyword.value = val;
  }, 500),
);

const columns: TableColumn<
  BankingTransactionResponse & {
    action: string;
  }
>[] = [
  {
    label: "Date",
    selector: (row) => formatDate(row.created_date),
    dataIndex: "date",
    showOnMobile: false,
  },
  {
    label: "Amount",
    selector: () => {},
    dataIndex: "amount",
    showOnMobile: false,
  },
  {
    label: "Account",
    selector: () => {},
    dataIndex: "account",
    showOnMobile: false,
  },
  {
    label: "Type",
    selector: (row) => row.type,
    dataIndex: "type",
    showOnMobile: false,
  },
  {
    label: "Counterparty",
    selector: (row) =>
      row.destination
        ? row.destination.counterparty?.details?.accountName ||
          `${row.destination_currency} ${row.destination.type}`
        : "N/A",
    dataIndex: "beneficiary",
    showOnMobile: false,
  },

  {
    label: "Status",
    selector: (row) => row.state,
    dataIndex: "status",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-account",
    showOnMobile: true,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-amount",
    showOnMobile: true,
  },
];

const handleMobileRowClicked = (row: BankingTransactionResponse) => {
  router.push(`/transactions/fiat/${row.id}`);
};

const handleTableChange = (params: TableChangeParams) => {
  currentPage.value = params.currentPage;
  perPage.value = params.perPage;
};

const resetFilters = () => {
  statesFilter.value = [];
  dateFromFilter.value = "";
  dateToFilter.value = "";

  typesFilter.value = [];
  setValues({
    states: "",
    asset_ids: "",
    types: "",
    created_date_from: "",
    created_date_to: "",
  });
};

watch(
  () => values.states,
  (val) => {
    if (val) {
      statesFilter.value = [val];
    } else {
      statesFilter.value = [];
    }
  },
);

watch(
  () => values.created_date_from,
  (val) => {
    if (val) {
      dateFromFilter.value = val;
    } else {
      dateFromFilter.value = "";
    }
  },
);

watch(
  () => values.created_date_to,
  (val) => {
    if (val) {
      dateToFilter.value = val;
    } else {
      dateToFilter.value = "";
    }
  },
);

watch(
  () => values.types,
  (val) => {
    if (val) {
      typesFilter.value = [val];
    } else {
      typesFilter.value = [];
    }
  },
);

watch(values, (val) => {
  if (val.created_date_from && val.created_date_to) {
    if (new Date(val.created_date_from) > new Date(val.created_date_to)) {
      toast.error("Date from cannot be greater than date to", {
        position: "top-right",
      });
      return;
    }
  }
});

const filterFields = reactive({
  states: defineInputBinds("states"),
  types: defineInputBinds("types"),
  created_date_from: defineInputBinds("created_date_from"),
  created_date_to: defineInputBinds("created_date_to"),
});
</script>
