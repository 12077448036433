<template>
  <div class="w-full min-h-full h-full bg-white">
    <div class="w-full">
      <header class="w-full flex justify-between items-center px-5 py-4">
        <h3 class="text-primary font-[800]">Export statements</h3>

        <button class="inline w-[16px] h-[18px]" @click="closeModal">
          <close-icon />
        </button>
      </header>

      <form
        v-if="hasPermission(LyncPermissions.statements_write)"
        :onsubmit="onSubmit"
        class="flex flex-col gap-y-2 px-5 py-3"
      >
        <div class="flex gap-x-2 flex-wrap md:flex-nowrap">
          <app-input
            label="Type"
            type="select"
            v-bind="formFields.format"
            :error-message="errors.format"
            grey-text
            required
          >
            <option value="" disabled selected>Select type</option>
            <option value="CSV">CSV</option>
            <option value="EXCEL">EXCEL</option>
          </app-input>
          <app-input
            label="Date from"
            placeholder="Select date"
            type="date"
            v-bind="formFields.from"
            :error-message="errors.from"
            grey-text
            required
          />
          <app-input
            label="Date to"
            placeholder="Select date"
            type="date"
            v-bind="formFields.to"
            :error-message="errors.to"
            grey-text
            required
          />
        </div>
        <app-button
          variant="primary"
          size="md"
          type="submit"
          :loading="submitting"
          :disabled="submitting"
          >Create new Export</app-button
        >
      </form>

      <div class="px-5 mt-8">
        <p class="font-medium text-text-secondary -mb-2">Previous Exports</p>
        <app-table
          v-if="!isError"
          :columns="columns"
          :data="statements?.items"
          :loading="isLoading"
          :pagination="{
            currentPage,
            perPage,
            totalItems: statements?.total_items || 0,
          }"
          @change="handleTableChange"
        >
          <template #column-format="props">
            <div
              class="w-fit flex justify-center items-center gap-x-1 text-text-secondary font-medium"
            >
              <document-icon /> {{ props.row.format }}
            </div>
          </template>
          <template #column-date="props">
            <div class="text-text-secondary flex flex-col font-medium text-xs">
              <span> {{ formatDate(props.row.from) }} - </span>
              <span>
                {{ formatDate(props.row.to) }}
              </span>
            </div>
          </template>

          <template #column-status="props">
            <transaction-status :status="props.row.state" />
          </template>

          <template #column-action="props">
            <app-button
              v-if="props.row.state === 'COMPLETE'"
              variant="outlined"
              size="sm"
              :disabled="!!downloading && downloading === props.row.id"
              :loading="!!downloading && downloading === props.row.id"
              @click="handleDownloadStatement(props.row.id)"
            >
              <download-icon-dark />
            </app-button>
          </template>

          <template #column-mobile-format="props">
            <div
              class="w-fit flex justify-center items-center gap-x-1 text-text-secondary font-medium"
            >
              <div
                class="w-2 h-2 rounded-full"
                :class="
                  props.row.state === 'COMPLETE' ? 'bg-success' : 'bg-secondary'
                "
              ></div>
              <document-icon /> {{ props.row.format }}
            </div>
          </template>

          <template #empty-state>
            <div
              class="w-full max-w-[300px] mx-auto flex flex-col gap-y-3 justify-center items-center"
            >
              <empty-data />
              <h4 class="font-bold text-base lg:text-lg">
                No transaction statement
              </h4>
              <p class="text-base text-center text-text-primary">
                You've not generated any transaction statement
              </p>
            </div>
          </template>
        </app-table>
        <error-component
          v-else-if="isError"
          message="Error fetching transaction statements"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useWriteResource } from "@/composables/use-resource";
import apiClient, { bankingUrl, cryptoUrl } from "@/helpers/apiClient";
import { errorMessage } from "@/helpers/error";
import { useForm } from "vee-validate";
import { reactive } from "vue";
import { useAppToast } from "@/composables";
import * as yup from "yup";
import { useGetTransactionsStatement } from "@/data-access/transactions";
import { ref } from "vue";
import { TableChangeParams, TableColumn } from "../shared/table/table.props";
import { QueryKeys, ServiceType, StatementsResponse } from "@/types";
import { formatDate } from "@/helpers";
import { useQueryClient } from "@tanstack/vue-query";
import { LyncPermissions } from "@/security/permissions";
import { usePermission } from "@/composables/use-permission";

const componentProps = defineProps<{
  closeModal: () => void;
  assetId: string;
  serviceType: ServiceType;
}>();

interface FormFields {
  from: string;
  to: string;
  format: string;
}

const { errors, handleSubmit, defineInputBinds } = useForm<FormFields>({
  validationSchema: yup.object({
    from: yup.string().required().label("Date from"),
    to: yup.string().required().label("Date to"),
    format: yup.string().required().label("Format"),
  }),
});

const currentPage = ref(1);
const perPage = ref(10);
const downloading = ref<null | string>("");
const { hasPermission } = usePermission();

const {
  data: statements,
  isError,
  isLoading,
} = useGetTransactionsStatement(
  {
    page: currentPage,
    limit: perPage,
    filters: {
      asset_id: ref(componentProps.assetId),
    },
  },
  componentProps.serviceType,
);
const toast = useAppToast();
const queryClient = useQueryClient();

const { execute: initiateCreateStatement, submitting } = useWriteResource(
  componentProps.serviceType === ServiceType.CRYPTO
    ? cryptoUrl(`statements`)
    : bankingUrl(`statements`),
  "post",
  {
    successTitle: "Statement created",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const onSubmit = handleSubmit(async (values) => {
  if (componentProps.serviceType === ServiceType.CRYPTO) {
    await initiateCreateStatement({
      body: {
        ...values,
        asset_id: componentProps.assetId,
      },
    });
  } else {
    await initiateCreateStatement({
      body: {
        format: values.format.toLowerCase(),
        from: values.from,
        to: values.to,
        account_id: componentProps.assetId,
      },
    });
  }
  queryClient.invalidateQueries({
    queryKey: [QueryKeys.BANKING_STATEMENTS],
  });
  queryClient.invalidateQueries({
    queryKey: [QueryKeys.CRYPTO_STATEMENTS],
  });
});

const formFields = reactive({
  from: defineInputBinds("from"),
  to: defineInputBinds("to"),
  format: defineInputBinds("format"),
});

const columns: TableColumn<
  StatementsResponse & {
    action: string;
  }
>[] = [
  {
    label: "",
    selector: () => {},
    dataIndex: "format",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "date",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "status",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-format",
    showOnMobile: true,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "date",
    showOnMobile: true,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: true,
  },
];

const handleTableChange = (params: TableChangeParams) => {
  currentPage.value = params.currentPage;
  perPage.value = params.perPage;
};

const handleDownloadStatement = async (statementId: string) => {
  try {
    downloading.value = statementId;
    const res = await apiClient.get(
      componentProps.serviceType === ServiceType.CRYPTO
        ? cryptoUrl(`/statements/${statementId}/download`)
        : bankingUrl(`/statements/${statementId}/download`),
      {
        responseType: "blob",
      },
    );
    downloading.value = null;
    const file = window.URL.createObjectURL(res.data);
    const docUrl = document.createElement("a");
    docUrl.href = file;
    docUrl.setAttribute("download", `transaction-statement-${statementId}`);
    document.body.appendChild(docUrl);
    docUrl.click();
    document.body.removeChild(docUrl);
  } catch (err) {
    downloading.value = null;
    toast.error(errorMessage(err), {
      position: "top-right",
    });
  }
};
</script>
